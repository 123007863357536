<template>
  <div id="dvTransfLic">
    <va-modal
      v-model="showRenovaLic"
      size="large"
      title="Transferir licença HBScalperPanel"
      okText="Confirmar"
      cancelText="Cancelar"
      @cancel="onCancel"
      @ok="transfereLicenca"
      noOutsideDismiss
      noEscDismiss
    >
      <div>
        <div class="csDataBlock">Corretora: {{ regLic.corretora }}</div>
        <div class="csDataBlock">Conta: {{ regLic.numconta }}</div>
        <div class="csDataBlock">
          Válidade atual:
          {{ moment(String(regLic.dataval)).format("DD/MM/YYYY HH:mm:ss") }}
        </div>

        <div id="dvPlano" class="dvCampo">
          <DxSelectBox
            :items="contasTransf"
            placeholder="Selecione a conta destino"
            label="Selecione a conta destino"
            @value-changed="selContaDestino"
          />
        </div>
      </div>

      <div id="dvRodape" class="dvCampo">
        <label
          ><strong
            >Após a transferência da licença, esta conta não poderá mais <br />
            transferir ou receber transferência de outras contas!</strong
          ></label
        >
      </div>
    </va-modal>
  </div>
</template>

<script>
import DxTextBox from "devextreme-vue/text-box";
import DxSelectBox from "devextreme-vue/select-box";
import conserv from "@/api/conserv";

export default {
  mixins: [conserv],
  props: {
    showRenovaLic: false,
    funcfecha: { type: Function },
  },
  components: {
    DxSelectBox,
    DxTextBox,
  },
  data() {
    return {
      contasTransf: [],
      regLic: {},
      toastDuration: 3500,
      toastIcon: "fa-star-o",
      toastPosition: "bottom-right",
      isToastFullWidth: false,
      dadosUserLogado: {},
      toastCor: "success",
      novaConta: 0,
    };
  },
  methods: {
    selContaDestino(e) {
      console.log(e);
      this.novaConta = e.value;
      console.log("Nova conta = ", this.novaConta);
    },
    updateTela(reg) {
      this.dadosUserLogado = JSON.parse(this.$store.state.userDados);
      this.regLic = reg;
      this.listaContasTF();
    },
    onCancel() {
      this.funcfecha();
    },
    async listaContasTF() {
      let regs = await this.connServ(
        "api/Licstrade/ListaContasTransf?xNumAtual=" + this.regLic.numconta,
        "get",
        ""
      );

      let regContas = regs.dados.registro;
      this.contasTransf = [];
      console.log("regContas = ", JSON.stringify(regContas));
      this.contasTransf = regContas.map((reg) => reg.numconta);
      console.log("this.contasTransf = ", JSON.stringify(this.contasTransf));
    },
    async transfereLicenca() {
      if (this.novaConta == 0) {
        this.toastCor = "danger";
        this.toastIcon = "fa-close";
        this.funcfecha();
        this.launchToast("Selecione a nova conta!");
        return false;
      }

      let dadosPost = {
        idLib: this.regLic.id,
        newIdLib: this.novaConta,
      };

      let regs = await this.connServ(
        "api/Licstrade/TransfereConta",
        "post",
        dadosPost
      );
      console.log("regs = ", JSON.stringify(regs));

      if (regs.bstatus) {
        this.launchToast("Licença transferida com sucesso!");
      }

      this.funcfecha(true);
    },
    launchToast(toastText) {
      this.$vaToast.init({
        message: toastText,
        iconClass: this.toastIcon,
        position: this.toastPosition,
        duration: Number(this.toastDuration),
        fullWidth: this.isToastFullWidth,
        color: this.toastCor,
      });
    },
  },
};
</script>

<style>
.csDataBlock {
  color: blue;
  padding-bottom: 5px;
  padding-top: 5px;
}

.csNewValidade {
  color: green;
  padding-bottom: 5px;
  padding-top: 5px;
}

.dvCampo {
  padding-top: 20px;
}

.cssTotal {
  padding: 5px;
  font-size: 22px;
  color: green;
  border: 2px;
  border-style: dashed;
}
</style>