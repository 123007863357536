<template>
  <div>
    <va-modal
      v-model="showAddContaCopy"
      size="large"
      title="Adicionar conta copy trade"
      okText="Confirmar"
      cancelText="Cancelar"
      @cancel="onCancel"
      @ok="adicionaRegistros"
      noOutsideDismiss
      noEscDismiss
    >
      <div>
        <div class="csDataBlock">Conta principal: {{ reg_lic.numconta }}</div>

        <div class="row">
          <div class="col">
            <div id="dvPontosGanha" class="dvCampo">
              <DxTextBox
                placeholder="0"
                label="Conta filha"
                v-model="contafilha"
              />
            </div>
          </div>
        </div>

        <va-alert color="danger" closeable v-if="bEdita">
          {{ xMsg }}
        </va-alert>
      </div>
    </va-modal>
  </div>
</template>

<script>
import DxTextBox from "devextreme-vue/text-box";
import conserv from "@/api/conserv";

export default {
  mixins: [conserv],
  props: {
    showAddContaCopy: false,
    funcfecha: { type: Function },
    reg_lic: { type: Object },
  },
  components: {
    DxTextBox,
  },
  data() {
    return {
      xMsg: "",
      contafilha: "",
      toastDuration: 2500,
      toastIcon: "fa-star-o",
      toastPosition: "bottom-right",
      toastColor: "success",
      isToastFullWidth: false,
    };
  },
  methods: {
    onCancel() {
      this.funcfecha();
    },
    async adicionaRegistros() {
      let dadosPost = {
        idconta: this.reg_lic.id,
        contafilha: this.contafilha,
      };

      let regs = await this.connServ(
        "api/Licstrade/AddCopyTrade",
        "post",
        dadosPost
      );

      if (regs.bstatus) {
        this.contafilha = "";
        this.toastColor = "success";
        if (regs.dados.status == "ERRO") {
          this.toastColor = "danger";
        }
        this.launchToast(regs.dados.msg);
      }

      this.funcfecha(true);
    },
    launchToast(toastText) {
      this.$vaToast.init({
        message: toastText,
        iconClass: this.toastIcon,
        position: this.toastPosition,
        color: this.toastColor,
        duration: Number(this.toastDuration),
        fullWidth: this.isToastFullWidth,
      });
    },
  },
};
</script>

<style>
.csDataBlock {
  color: blue;
  padding-bottom: 5px;
  padding-top: 5px;
}

.dvCampo {
  padding-top: 20px;
  padding-right: 10px;
}
</style>