const cbsimnao = [
    {
        texto: "Todos",
        valor: -1,
    },
    {
        texto: "SIM",
        valor: 1,
    },
    {
        texto: "NÃO",
        valor: 0,
    },
];

export default cbsimnao;