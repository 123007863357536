<template>
  <div id="dvTradesGeral">
    <div id="dvGrid">
      <dx-data-grid
        ref="gdListaVendas"
        class="dx-card wide-card"
        :data-source="data"
        :focused-row-index="0"
        :show-borders="false"
        :focused-row-enabled="true"
        :column-auto-width="true"
        :column-hiding-enabled="true"
        :context-menu-enabled="true"
        key-expr="id"
        @toolbar-preparing="onToolbarPreparing($event)"
      >
        <dx-paging :page-size="50" />
        <dx-pager :show-page-size-selector="true" :show-info="true" />
        <dx-filter-row :visible="true" />
        <dx-header-filter :visible="true" />

        <dx-column type="buttons">
          <dx-button
            icon="clear"
            type="normal"
            text="Excluir conta"
            :on-click="Excluiconta"
          />
        </dx-column>

        <dx-column data-field="id" caption="Id" :width="100" />
        <dx-column data-field="numconta" caption="Conta" />
      </dx-data-grid>
    </div>

    <DxLoadPanel
      :position="{ of: '#dvGrid' }"
      v-model:visible="loadingVisible"
      :shading="true"
      shading-color="rgba(0,0,0,0.4)"
    />

    <add-copy-trade
      :showAddContaCopy="atvPopAdd"
      :reg_lic="reg_lic"
      :funcfecha="FechaAdd"
      ref="telaPop"
    />
  </div>
</template>

<script>
import conserv from "@/api/conserv";
import { DxLoadPanel } from "devextreme-vue/load-panel";
import AddCopyTrade from "./AddContaCopy.vue";

import DxDataGrid, {
  DxColumn,
  DxFilterRow,
  DxPager,
  DxPaging,
  DxExport,
  DxButton,
  DxHeaderFilter,
} from "devextreme-vue/data-grid";

export default {
  props: {
    reg_lic: { type: Object },
  },
  mixins: [conserv],
  components: {
    DxDataGrid,
    DxColumn,
    DxFilterRow,
    DxPager,
    DxPaging,
    DxExport,
    DxButton,
    DxLoadPanel,
    DxHeaderFilter,
    AddCopyTrade,
  },
  data() {
    return {
      loadingVisible: false,
      data: null,
      atvPopAdd: false,
    };
  },
  methods: {
    FechaAdd(bupdate = false) {
      this.atvPopAdd = false;
      if (bupdate) {
        this.ListaRegistros();
      }
    },
    AdicionaRegistro() {
      this.atvPopAdd = true;
    },
    async Excluiconta(e) {
      if (!confirm("confirma a exclusão da conta?")) return;

      this.loadingVisible = true;
      var reg = e.row.data;
      let regs = await this.connServ(
        "api/Licstrade/ExcluiContaCopyTrade?id=" +
          reg.id +
          "&idContaPai=" +
          this.reg_lic.id,
        "get",
        ""
      );
      console.log(JSON.stringify(regs));
      this.loadingVisible = false;
      //this.launchToast(regs.dados.msg);
      this.$refs.telaPop.launchToast(regs.dados.msg);
      this.ListaRegistros();
    },
    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift(
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "refresh",
            text: "Atualizar",
            elementAttr: {
              id: "btAtualiza",
            },
            onClick: this.ListaRegistros.bind(this),
          },
        },
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "add",
            text: "Adicionar conta",
            elementAttr: {
              id: "btAddConta",
            },
            onClick: this.AdicionaRegistro.bind(this),
          },
        }
      );
    },
    async ListaRegistros() {
      this.loadingVisible = true;
      let regs = await this.connServ(
        "api/Licstrade/ListaCopyTrades?idConta=" + this.reg_lic.id,
        "get",
        ""
      );
      console.log(JSON.stringify(regs));
      this.data = [...regs.dados.registro];
      this.loadingVisible = false;
    },
  },
  mounted() {
    this.ListaRegistros();
  },
};
</script>

<style>
</style>