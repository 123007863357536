<template>
  <div id="dvRenovaLicTrade">
    <va-modal
      v-model="showRenovaLic"
      size="large"
      title="Renovar licença HBScalperPanel"
      okText="Confirmar"
      cancelText="Cancelar"
      @cancel="onCancel"
      @ok="renovaLicenca"
      noOutsideDismiss
      noEscDismiss
    >
      <div>
        <div class="csDataBlock">Corretora: {{ regLic.corretora }}</div>
        <div class="csDataBlock">Conta: {{ regLic.numconta }}</div>
        <div class="csDataBlock">
          Válidade atual:
          {{ moment(String(regLic.dataval)).format("DD/MM/YYYY HH:mm:ss") }}
        </div>
        <div class="csNewValidade">
          Nova validade:
          {{ dataNovaValidade }}
        </div>

        <div id="dvPlano" class="dvCampo">
          <DxSelectBox
            :items="planosVenda"
            placeholder="Selecione um plano"
            label="Selecione um plano..."
            @value-changed="selPlano"
          />
        </div>

        <div id="dvCopy" class="dvCampo">
          <div class="row">
            <div class="col">
              <DxSwitch v-model:value="bUsaCopy" @value-changed="selPlano2" />
            </div>
            <div class="col">
              Adicionar CopyTrade
              <strong
                >+
                {{
                  new Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(valorCopySel)
                }}
              </strong>
            </div>
          </div>

          <div id="dvTotal" class="cssTotal">
            Total:
            {{
              new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(valorTotalPagar)
            }}
          </div>
        </div>
      </div>
    </va-modal>
  </div>
</template>

<script>
import DxTextBox from "devextreme-vue/text-box";
import DxSelectBox from "devextreme-vue/select-box";
import conserv from "@/api/conserv";
import { DxSwitch } from "devextreme-vue/switch";

export default {
  mixins: [conserv],
  props: {
    showRenovaLic: false,
    funcfecha: { type: Function },
  },
  components: {
    DxSelectBox,
    DxTextBox,
    DxSwitch,
  },
  data() {
    return {
      planosVenda: [],
      regLic: {},
      toastDuration: 3500,
      toastIcon: "fa-star-o",
      toastPosition: "bottom-right",
      isToastFullWidth: false,
      dataNovaValidade: "",
      valoresCopy: {},
      valoresPlanos: {},
      valorCopySel: 0,
      valorTotalPagar: 0,
      idPlano: 0,
      dadosUserLogado: {},
      toastCor: "success",
      bUsaCopy: false,
    };
  },
  methods: {
    selPlano(e) {
      let daysAdd = 0;
      switch (e.value[0]) {
        case "1":
          daysAdd = 30;
          this.idPlano = 1;
          this.valorCopySel = this.valoresCopy.valor1c;
          this.valorTotalPagar = this.valoresPlanos.valor1;
          break;
        case "2":
          daysAdd = 180;
          this.idPlano = 2;
          this.valorCopySel = this.valoresCopy.valor2c;
          this.valorTotalPagar = this.valoresPlanos.valor2;
          break;
        case "3":
          daysAdd = 360;
          this.idPlano = 3;
          this.valorCopySel = this.valoresCopy.valor3c;
          this.valorTotalPagar = this.valoresPlanos.valor3;
          break;
      }

      if (this.bUsaCopy) this.valorTotalPagar += this.valorCopySel;

      var new_date = this.moment(String(this.regLic.dataval))
        .add(daysAdd, "days")
        .format("DD/MM/YYYY HH:mm:ss");

      this.dataNovaValidade = new_date;
      console.log("new_date = ", new_date, daysAdd);
      console.log("ValorCopy = ", this.valorCopySel);
    },
    selPlano2() {
      switch (this.idPlano) {
        case 1:
          this.valorTotalPagar = this.valoresPlanos.valor1;
          break;
        case 2:
          this.valorTotalPagar = this.valoresPlanos.valor2;
          break;
        case 3:
          this.valorTotalPagar = this.valoresPlanos.valor3;
          break;
      }
      if (this.bUsaCopy) this.valorTotalPagar += this.valorCopySel;
    },
    updateTela(reg) {
      this.dadosUserLogado = JSON.parse(this.$store.state.userDados);
      this.regLic = reg;
      this.dataNovaValidade = this.moment(String(this.regLic.dataval)).format(
        "DD/MM/YYYY HH:mm:ss"
      );
      this.getPrecos();
    },
    onCancel() {
      this.funcfecha();
    },
    async getPrecos() {
      let regs = await this.connServ(
        "api/Vendas/PegaValorSistema?xId=1&numConta=" + this.regLic.numconta,
        "get",
        ""
      );
      this.valoresCopy.valor1c = regs.dados.registro.valor1c;
      this.valoresCopy.valor2c = regs.dados.registro.valor2c;
      this.valoresCopy.valor3c = regs.dados.registro.valor3c;

      this.valoresPlanos.valor1 = regs.dados.registro.valor1;
      this.valoresPlanos.valor2 = regs.dados.registro.valor2;
      this.valoresPlanos.valor3 = regs.dados.registro.valor3;

      this.planosVenda = [];
      this.planosVenda.push(
        "1-Plano mensal - " +
          new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          }).format(regs.dados.registro.valor1)
      );
      this.planosVenda.push(
        "2-Plano semestral - " +
          new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          }).format(regs.dados.registro.valor2)
      );
      this.planosVenda.push(
        "3-Plano anual - " +
          new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          }).format(regs.dados.registro.valor3)
      );
    },
    async renovaLicenca() {
      if (this.idPlano == 0) {
        this.toastCor = "danger";
        this.toastIcon = "fa-close";
        this.launchToast("Selecione o plano para continuar!");
        return;
      }
      let dadosPost = {
        idSoft: 1,
        idLib: this.regLic.id,
        idPlano: this.idPlano,
        idUser: this.dadosUserLogado.id,
        idPc: "SITE",
        usaCopy: this.bUsaCopy,
      };

      let regs = await this.connServ(
        "api/Vendas/EfetuaPagamentoMP",
        "post",
        dadosPost
      );
      console.log("regs = ", JSON.stringify(regs));

      if (regs.bstatus) {
        this.launchToast("Venda gerada com sucesso!");
        window.open(regs.dados.msg, "_blank").focus();
      }

      this.funcfecha(true);
    },
    launchToast(toastText) {
      this.$vaToast.init({
        message: toastText,
        iconClass: this.toastIcon,
        position: this.toastPosition,
        duration: Number(this.toastDuration),
        fullWidth: this.isToastFullWidth,
        color: this.toastCor,
      });
    },
  },
};
</script>

<style>
.csDataBlock {
  color: blue;
  padding-bottom: 5px;
  padding-top: 5px;
}

.csNewValidade {
  color: green;
  padding-bottom: 5px;
  padding-top: 5px;
}

.dvCampo {
  padding-top: 20px;
}

.cssTotal {
  padding: 5px;
  font-size: 22px;
  color: green;
  border: 2px;
  border-style: dashed;
}
</style>