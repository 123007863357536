<template>
  <div id="dvListaTrades">
    <label>Detalhes da conta: {{ dados.numconta }}</label>
    <div id="dvAcao"></div>

    <div id="tabs" class="container">
      <div class="tabs">
        <a
          v-on:click="activetab = 1"
          v-bind:class="[activetab === 1 ? 'active' : '']"
          >Operações</a
        >
        <a
          v-on:click="activetab = 2"
          v-bind:class="[activetab === 2 ? 'active' : '']"
          >Contas CopyTrade</a
        >
      </div>

      <div class="content">
        <div v-if="activetab === 1" class="tabcontent">
          <grid-trades :reg_lic="dados" />
        </div>

        <div v-if="activetab === 2" class="tabcontent">
          <grid-copy-trade :reg_lic="dados" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GridTrades from "./GridTrades.vue";
import GridCopyTrade from "./GridCopyTrade.vue";

export default {
  components: {
    GridTrades,
    GridCopyTrade,
  },
  props: {
    dados: { type: Object },
  },
  data() {
    return {
      activetab: 1,
    };
  },
};
</script>

<style>
/* RESET */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.container {
  min-width: 420px;
  margin: 40px auto;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.9em;
  color: #888;
}

/* Style the tabs */
.tabs {
  overflow: hidden;
  margin-left: 10px;
  margin-bottom: -2px;
}

.tabs ul {
  list-style-type: none;
  margin-left: 10px;
}

.tabs a {
  float: left;
  cursor: pointer;
  padding: 12px 24px;
  transition: background-color 0.2s;
  border: 1px solid #ccc;
  border-right: none;
  background-color: #f1f1f1;
  border-radius: 10px 10px 0 0;
  font-weight: bold;
}
.tabs a:last-child {
  border-right: 1px solid #ccc;
}

/* Change background color of tabs on hover */
.tabs a:hover {
  background-color: #aaa;
  color: #fff;
}

/* Styling for active tab */
.tabs a.active {
  background-color: #fff;
  color: #484848;
  border-bottom: 2px solid #fff;
  cursor: default;
}

/* Style the tab content */
.tabcontent {
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 3px 3px 6px #e1e1e1;
}
</style>