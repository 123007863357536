<template>
  <div>
    <va-modal
      v-model="showMediumModal"
      size="large"
      title="Editar bloqueio MT5"
      okText="Confirmar"
      cancelText="Cancelar"
      @cancel="onCancel"
      @ok="alteraRegistros"
      noOutsideDismiss
      noEscDismiss
    >
      <div>
        <div class="csDataBlock">Corretora: {{ regLic.corretora }}</div>
        <div class="csDataBlock">Conta: {{ regLic.numconta }}</div>

        <div class="row">
          <div class="col">
            <div id="dvPontosGanha" class="dvCampo">
              <DxTextBox
                placeholder="0"
                label="Pontos ganhos"
                :disabled="bEdita"
                v-model="regLic.ptsganhafecha"
              />
            </div>
          </div>

          <div class="col">
            <div id="dvPontosPerde" class="dvCampo">
              <DxTextBox
                placeholder="0"
                label="Pontos perdidos"
                :disabled="bEdita"
                v-model="regLic.ptsperdefecha"
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div id="dvValorGanha" class="dvCampo">
              <DxTextBox
                placeholder="0"
                label="Entradas"
                :disabled="bEdita"
                v-model="regLic.qntoperafecha"
              />
            </div>
          </div>
          <div class="vx-col dvCampo">
            <label>Mostrar conta</label>
            <DxSwitch v-model:value="bShowPn" />
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div id="dvTempoEntra" class="dvCampo">
              <DxTextBox
                placeholder="0"
                label="Tempo para nova entrada"
                :disabled="bEdita"
                v-model="regLic.tempoentra"
              />
            </div>
          </div>
          <div class="vx-col dvCampo"></div>
        </div>

        <va-alert color="danger" closeable v-if="bEdita">
          {{ xMsg }}
        </va-alert>
      </div>
    </va-modal>
  </div>
</template>

<script>
import DxTextBox from "devextreme-vue/text-box";
import conserv from "@/api/conserv";
import { DxSwitch } from "devextreme-vue/switch";

export default {
  mixins: [conserv],
  props: {
    showMediumModal: false,
    funcfecha: { type: Function },
  },
  components: {
    DxTextBox,
    DxSwitch,
  },
  data() {
    return {
      dadosUserLogado: {},
      regLic: {},
      bEdita: false,
      data_val: "",
      xMsg: "",
      toastDuration: 2500,
      toastIcon: "fa-star-o",
      toastPosition: "bottom-right",
      isToastFullWidth: false,
      bShowPn: false,
    };
  },
  mounted() {
    this.dadosUserLogado = JSON.parse(this.$store.state.userDados);
  },
  methods: {
    onCancel() {
      this.funcfecha();
    },
    async alteraRegistros() {
      let dadosPost = {
        id: this.regLic.id,
        qntPtsGanha: this.regLic.ptsganhafecha,
        qntPtsFecha: this.regLic.ptsperdefecha,
        qntOperacao: this.regLic.qntoperafecha,
        tempoEntra: this.regLic.tempoentra,
        showpn: this.bShowPn == true ? 1 : 0,
      };

      console.log(JSON.stringify(dadosPost));

      let regs = await this.connServ(
        "api/Licstrade/AtualizaBlockMT5",
        "post",
        dadosPost
      );

      if (regs.bstatus) {
        this.launchToast("Registro atualizado com sucesso");
        this.data_val = "";
      }

      this.funcfecha(true);
    },
    async verEdt(reg) {
      this.regLic = reg;
      this.bShowPn = reg.showpn == 1 ? true : false;
      console.log("this.regLic = ", JSON.stringify(this.regLic));

      let regs = await this.connServ(
        "api/Licstrade/VerificaEdicaoLimites?idConta=" + this.regLic.id,
        "get",
        ""
      );

      console.log("regs = ", JSON.stringify(regs));

      if (regs.bstatus) {
        if (regs.dados.msg == "True") {
          console.log("NÃO PODE EDITAR...");
          this.bEdita = true;
          this.xMsg =
            "Você só poderá alterar as configurações de bloqueio após 00:00 hrs";
        } else {
          console.log("PODE EDITAR...");
          this.xMsg = "";
          this.bEdita = false;
        }
      }
    },
    launchToast(toastText) {
      this.$vaToast.init({
        message: toastText,
        iconClass: this.toastIcon,
        position: this.toastPosition,
        duration: Number(this.toastDuration),
        fullWidth: this.isToastFullWidth,
      });
    },
  },
};
</script>

<style>
.csDataBlock {
  color: blue;
  padding-bottom: 5px;
  padding-top: 5px;
}

.dvCampo {
  padding-top: 20px;
  padding-right: 10px;
}
</style>