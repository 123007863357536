<template>
  <div id="dvTradesGeral">
    <div id="dvGrid">
      <dx-data-grid
        ref="gdListaVendas"
        class="dx-card wide-card"
        :data-source="data"
        :focused-row-index="0"
        :show-borders="false"
        :focused-row-enabled="true"
        :column-auto-width="true"
        :column-hiding-enabled="true"
        :context-menu-enabled="true"
        key-expr="id"
        @toolbar-preparing="onToolbarPreparing($event)"
      >
        <dx-paging :page-size="50" />
        <dx-pager :show-page-size-selector="true" :show-info="true" />
        <dx-filter-row :visible="true" />
        <dx-header-filter :visible="true" />

        <dx-column data-field="id" caption="Id" :visible="false" />
        <dx-column data-field="acao" caption="Operação" />
        <dx-column data-field="lote" caption="Lotes" />
        <dx-column data-field="pontos" caption="Pontos" />
        <dx-column data-field="lucro" caption="Lucro" />
        <dx-column
          data-field="datahoraop"
          caption="Data operação"
          data-type="date"
          format="dd/MM/yyyy hh:mm:ss"
        />
        <dx-column
          data-field="datahorasys"
          caption="Data local"
          data-type="date"
          format="dd/MM/yyyy hh:mm:ss"
        />

        <DxSummary>
          <DxTotalItem
            column="lucro"
            summary-type="sum"
            :value-format="formatGrupo"
            displayFormat="{0}"
          />
          <DxTotalItem column="pontos" summary-type="sum" displayFormat="{0}" />
        </DxSummary>
      </dx-data-grid>
    </div>

    <DxLoadPanel
      :position="{ of: '#dvGrid' }"
      v-model:visible="loadingVisible"
      :shading="true"
      shading-color="rgba(0,0,0,0.4)"
    />
  </div>
</template>

<script>
import conserv from "@/api/conserv";
import { DxLoadPanel } from "devextreme-vue/load-panel";

import DxDataGrid, {
  DxColumn,
  DxFilterRow,
  DxHeaderFilter,
  DxPager,
  DxPaging,
  DxExport,
  DxButton,
  DxSummary,
  DxTotalItem,
} from "devextreme-vue/data-grid";

export default {
  props: {
    reg_lic: { type: Object },
  },
  mixins: [conserv],
  components: {
    DxDataGrid,
    DxColumn,
    DxFilterRow,
    DxPager,
    DxPaging,
    DxExport,
    DxButton,
    DxLoadPanel,
    DxSummary,
    DxHeaderFilter,
    DxTotalItem,
  },
  data() {
    return {
      loadingVisible: false,
      data: null,
      formatGrupo: {
        style: "currency",
        currency: "USD",
        useGrouping: true,
        precision: 2,
      },
    };
  },
  methods: {
    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({
        location: "before",
        widget: "dxButton",
        options: {
          icon: "refresh",
          text: "Atualizar",
          elementAttr: {
            id: "btAtualiza",
          },
          onClick: this.ListaRegistros.bind(this),
        },
      });
    },
    async ListaRegistros() {
      this.loadingVisible = true;
      let regs = await this.connServ(
        "api/Licstrade/ListaTradesConta?idConta=" + this.reg_lic.id,
        "get",
        ""
      );
      console.log(JSON.stringify(regs));
      this.data = [...regs.dados.registro];
      this.loadingVisible = false;
    },
  },
  mounted() {
    this.ListaRegistros();
  },
};
</script>

<style>
</style>