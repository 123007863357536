<template>
  <div class="licencas">
    <div id="dvcard">
      <va-card>
        <va-card-title>
          <h1>FILTROS</h1>
        </va-card-title>
        <va-card-content>
          <div class="row align--center mb-1">
            <div class="flex xs12 sm3">
              <DxSelectBox
                id="cbAtivo"
                :data-source="dataAtivo"
                :value="combosimnao[1].valor"
                display-expr="texto"
                value-expr="valor"
                label="Contas ativas"
                label-mode="static"
                @ValueChanged="SelecionaFiltros($event)"
              />
            </div>

            <div class="flex xs12 sm6">
              <div class="d-flex justify--end"></div>
            </div>
          </div>
        </va-card-content>
      </va-card>
    </div>

    <div id="dvGrid">
      <dx-data-grid
        ref="gdListaVendas"
        class="dx-card wide-card"
        :data-source="dataVenda"
        :focused-row-index="0"
        :show-borders="false"
        :focused-row-enabled="true"
        :column-auto-width="true"
        :column-hiding-enabled="true"
        :context-menu-enabled="true"
        key-expr="id"
        @toolbar-preparing="onToolbarPreparing($event)"
      >
        <dx-paging :page-size="50" />
        <dx-pager :show-page-size-selector="true" :show-info="true" />
        <dx-filter-row :visible="true" />
        <dx-header-filter :visible="true" />

        <dx-column type="buttons">
          <dx-button
            icon="edit"
            type="normal"
            text="Editar bloqueio MT5"
            :on-click="EditaRegistro"
          />
          <dx-button
            :visible="verTipoConta"
            icon="money"
            type="normal"
            text="Renovar licença"
            :on-click="RenovaLicenca"
          />
          <dx-button
            :visible="verTipoContaTransf"
            icon="chevrondoubleright"
            type="normal"
            text="Migrar licença"
            :on-click="TransfLicenca"
          />
          <dx-button
            :visible="verTipoContaDemo"
            icon="clear"
            type="normal"
            text="Excluir conta"
            :on-click="Excluiconta"
          />
        </dx-column>

        <dx-column data-field="id" caption="Id" :visible="false" />
        <dx-column
          data-field="numconta"
          caption="Número da conta"
          :width="150"
        />
        <dx-column data-field="corretora" caption="Corretora" />
        <dx-column data-field="tipoconta" caption="Tipo conta" />
        <dx-column data-field="nomeconta" caption="Nome conta" />
        <dx-column data-field="ptsganhafecha" caption="Pts ganhos" />
        <dx-column data-field="ptsperdefecha" caption="Pts perdidos" />
        <dx-column data-field="qntoperafecha" caption="Entradas" />
        <dx-column
          data-field="dataval"
          caption="Data validade"
          data-type="date"
          format="dd/MM/yyyy"
        />

        <DxMasterDetail :enabled="true" template="masterDetailTemplate" />
        <template #masterDetailTemplate="{ data: dados }">
          <ListaTrades :dados="dados.data" />
        </template>
      </dx-data-grid>
    </div>

    <div>
      <h3><strong>TERMOS DE USO DO SISTEMA</strong></h3>
      <ul>
        <li>
          - Não nos responsabilizamos por falhas nos equipamentos usados, bem
          como a qualidade da internet e rotas até o servidor.
        </li>
        <li>
          - As configurações são livres, utilize uma conta demo para testar suas
          estratégias, não nos responsabilizamos pelas configurações utilizadas.
        </li>
        <li>
          - Para utilização do CopyTrade indicamos uma configuração mínima de um
          I5 (ou similar) com 8GB de RAM, não nos responsabilizamos por falhas
          do módulo.
        </li>
        <li>
          - As licenças das contas são intransferíveis em qualquer
          circunstância.
        </li>
        <li>
          - Não é permitido registrar a mesma conta a mais de um e-mail
          (usuário).
        </li>
      </ul>
    </div>

    <DxLoadPanel
      :position="{ of: '#dvListaVendas' }"
      v-model:visible="loadingVisible"
      :shading="true"
      shading-color="rgba(0,0,0,0.4)"
    />

    <EditaMT5
      :showMediumModal="atvPopEdita"
      :funcfecha="FechaEdita"
      ref="telaPop"
    />

    <RenovarLicTrade
      :showRenovaLic="atvPopRenova"
      :funcfecha="FechaRenova"
      ref="telaRenova"
    />

    <TransfLic
      :showRenovaLic="atvPopTransf"
      :funcfecha="FechaTransf"
      ref="telaTransf"
    />
  </div>
</template>

<script>
import conserv from "@/api/conserv";
import EditaMT5 from "./EditaMT5.vue";
import RenovarLicTrade from "./RenovarLicTrade.vue";
import TransfLic from "./TransfLic.vue";
import ListaTrades from "./ListaTrades.vue";
import combosimnao from "@/models/ComboSimNao";
import { DxLoadPanel } from "devextreme-vue/load-panel";
import ArrayStore from "devextreme/data/array_store";

import DxDataGrid, {
  DxColumn,
  DxFilterRow,
  DxHeaderFilter,
  DxPager,
  DxPaging,
  DxExport,
  DxButton,
  DxMasterDetail,
} from "devextreme-vue/data-grid";
import { DxSelectBox } from "devextreme-vue/select-box";

export default {
  name: "licencas",
  mixins: [conserv],
  components: {
    EditaMT5,
    RenovarLicTrade,
    TransfLic,
    ListaTrades,
    DxFilterRow,
    DxHeaderFilter,
    DxLoadPanel,
    DxDataGrid,
    DxColumn,
    DxPager,
    DxPaging,
    DxExport,
    DxButton,
    DxMasterDetail,
    DxSelectBox,
  },
  data() {
    return {
      combosimnao,
      dadosUserLogado: {},
      loadingVisible: false,
      dataVenda: null,
      regEdicao: {},
      fShowAtv: 1,
      atvPopEdita: false,
      atvPopRenova: false,
      atvPopTransf: false,
      dataAtivo: new ArrayStore({
        data: combosimnao,
        key: "valor",
      }),
    };
  },
  methods: {
    SelecionaFiltros(e) {
      switch (e.element.id) {
        case "cbAtivo":
          this.fShowAtv = e.value;
          break;
      }

      console.log("sel filtros = ", JSON.stringify(this.fShowAtv));
    },
    verTipoConta(e) {
      if (e.row.data.tipoconta == "Conta Demo") return false;
      else return true;
    },
    verTipoContaTransf(e) {
      //console.log("e.row.data.dataval = ", e.row.data.dataval);
      let xAno = this.moment(e.row.data.dataval, "YYYY-MM-DD").year();
      //console.log("xAno = ", xAno);

      if (
        (e.row.data.tipoconta != "Conta Demo" &&
          e.row.data.numcontasend != "0") ||
        xAno == 2099
      )
        return false;
      else return true;
    },
    verTipoContaDemo(e) {
      if (e.row.data.tipoconta != "Conta Demo") return false;
      else return true;
    },
    FechaEdita(bupdate = false) {
      this.atvPopEdita = false;
      if (bupdate) {
        this.ListaRegistros();
      }
    },
    FechaRenova(bupdate = false) {
      this.atvPopRenova = false;
      if (bupdate) {
        this.ListaRegistros();
      }
    },
    FechaTransf(bupdate = false) {
      this.atvPopTransf = false;
      if (bupdate) {
        this.ListaRegistros();
      }
    },
    async ListaRegistros() {
      this.loadingVisible = true;
      let dadosPost = { showpn: this.fShowAtv };

      console.log(JSON.stringify(dadosPost));

      let regs = await this.connServ(
        "api/Licstrade/ListaLicsTraderUser",
        "post",
        dadosPost
      );
      console.log(JSON.stringify(regs));
      this.dataVenda = [...regs.dados.registro];
      this.loadingVisible = false;
    },
    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({
        location: "before",
        widget: "dxButton",
        options: {
          icon: "refresh",
          text: "Atualizar",
          elementAttr: {
            id: "btAtualiza",
          },
          onClick: this.ListaRegistros.bind(this),
        },
      });
    },
    EditaRegistro(e) {
      var reg = e.row.data;
      this.regEdicao = reg;
      this.atvPopEdita = true;
      this.$refs.telaPop.verEdt(reg);
    },
    RenovaLicenca(e) {
      var reg = e.row.data;
      this.regEdicao = reg;
      this.atvPopRenova = true;
      this.$refs.telaRenova.updateTela(reg);
    },
    TransfLicenca(e) {
      var reg = e.row.data;
      this.regEdicao = reg;
      this.atvPopTransf = true;
      this.$refs.telaTransf.updateTela(reg);
    },
    async Excluiconta(e) {
      if (!confirm("confirma a exclusão da conta?")) return;

      this.loadingVisible = true;
      var reg = e.row.data;
      let regs = await this.connServ(
        "api/Licstrade/ExcluiContaDemo?idConta=" + reg.id,
        "get",
        ""
      );
      console.log(JSON.stringify(regs));
      this.loadingVisible = false;
      this.launchToast(regs.dados.msg);
      this.ListaRegistros();
    },
    launchToast(toastText) {
      this.$vaToast.init({
        message: toastText,
        iconClass: this.toastIcon,
        position: this.toastPosition,
        duration: Number(this.toastDuration),
        fullWidth: this.isToastFullWidth,
        color: this.toastCor,
      });
    },
  },
  mounted() {
    this.ListaRegistros();
    this.dadosUserLogado = JSON.parse(this.$store.state.userDados);
  },
};
</script>

<style>
.cssInfo {
  padding-top: 10px;
}

.cssDemo {
  color: chocolate;
}

.cssReal {
  color: green;
}
</style>